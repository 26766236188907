// teacherRoleType 1-任课教师 2-班主任 3-备课组长 4-年级组长 5-校领导
// 统计报表 权限管理
import { getStore } from "@/core/util/store";
// roleId
// 3	学校用户
// 除了在线辅导外的所有权限
// 4	学校学生
// 精准教学（学生学情）、在线辅导
// 5	学校老师
// 资源库、精准教学（所有学情）、在线辅导
function validRole(roles = [], item) {
  const { teacherRoleType, teacherRoleList } = getStore({
    name: "userInfo",
  });
  if (teacherRoleList) {
    let maxRoleType = Math.max(...teacherRoleType.split(",").map(Number));
    let maxRoleList = teacherRoleList.filter(
      (item) => item.type == maxRoleType
    );
    if (roles.includes(maxRoleType)) {
      if (["年级榜", "年级试卷分析"].includes(item.title)) {
        if ([4, 5].includes(maxRoleType)) {
          if (maxRoleType == 4) {
            let roleItem = maxRoleList.find(
              (item) => item.year == this.currentExam.year
            );
            if (roleItem) {
              return roleItem.level == this.currentExam.level;
            }
            return false;
          }
          return true;
        } else if (maxRoleType == 3) {
          let roleItems = maxRoleList.filter(
            (item) => item.year == this.currentExam.year
          );
          if (roleItems.length) {
            let levels = roleItems.map((item) => item.level);
            let roleSubjects = roleItems.map((item) => item.subjectId);
            let subjectIds = this.currentExam.subjectIds.split(",").map(Number);
            return (
              levels.includes(this.currentExam.level) &&
              haveIntersection(roleSubjects, subjectIds)
            );
          }
          return false;
        }
        return false;
      }
      return true;
    }
    return false;
  }
  return true;
}
const haveIntersection = (arr1, arr2) => {
  // 求两数组是否有交集
  return arr1.some((item) => arr2.includes(item));
};
export function validateFunc(item) {
  let roles = item.role;
  const { roleId } = getStore({
    name: "userInfo",
  });

  if ([1, 2, 3, 6].includes(roleId)) {
    return true;
  } else if (roleId == 4) {
    return false;
  } else {
    const valid = validRole.bind(this)(roles, item);
    if (valid) {
      return true;
    } else {
      return false;
    }
  }
}
// 验证是否为老师角色但是什么权限都没有的情况 这个时候展示缺省图
export const validateTeacherNoAnyRole = () => {
  let accountRole = JSON.parse(sessionStorage.getItem("accountRole"));
  const { roleId } = getStore({
    name: "userInfo",
  });
  if (roleId == 4 && !accountRole.length) {
    return false;
  }
  return true;
};

// 验证是否为必考科目
export const validRequiredSubject = (subjectId) => {
  const requiredSubjectIds = [1, 2, 3]; // 必考科目id [语文,数学,英语]
  const result = requiredSubjectIds.some((item) => item == subjectId);
  return result;
};
