<template>
  <div v-loading="containerLoading" class="once">
    <div class="filter-box card">
      <filter-component :options="filterOptions" @change="getFilterValue">
      </filter-component>

      <el-select
        v-model="statId"
        placeholder="请选择"
        style="width: 500px"
        @change="statIdChange"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="card">
      <div v-if="examTitle" class="exam-title">{{ examTitle }}-分析结果</div>
      <div v-if="options.length && validateTeacherNoAnyRole()" class="content">
        <template v-for="(item, index) in contentArr">
          <div
            v-if="validateFunc(item)"
            :key="index + 'item'"
            class="content-item"
          >
            <div class="content-item-title">
              <img src="@/assets/imgs/analy/flag.png" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <div class="operate">
              <div class="filter">
                <el-select
                  v-if="item.subjectId !== null"
                  v-model="item.subjectId"
                  size="mini"
                  style="width: 92px"
                  placeholder="请选择"
                  @change="subjectChange(item, index)"
                >
                  <el-option
                    v-for="i in item.subjectList"
                    :key="i.id"
                    :label="i.name"
                    :value="i.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="item.classNum"
                  v-model="item.classNum"
                  size="mini"
                  style="width: 75px; margin-left: 5px"
                  placeholder="请选择"
                  @change="(e) => classChange(item, e)"
                >
                  <el-option
                    v-for="i in item.classList || classList"
                    :key="i.name"
                    :label="i.name"
                    :value="i.name"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="item.title == '学生分析'"
                  v-model="item.studentId"
                  size="mini"
                  style="width: 75px; margin-left: 5px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="i in studentList"
                    :key="i.studentId"
                    :label="i.name"
                    :value="i.studentId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-button
                  v-if="item.level && $route.query.ruleId == '0'"
                  size="mini"
                  type="primary"
                  plain
                  @click="detail(item, 'level')"
                >
                  查看等级
                </el-button>
                <el-button
                  v-if="item.type === 'expoerPaper'"
                  size="mini"
                  type="primary"
                  plain
                  @click="detail(item)"
                >
                  进入操作
                </el-button>
                <el-button
                  v-else-if="item.export"
                  v-loading="item.loading"
                  size="mini"
                  type="primary"
                  plain
                  @click="detail(item)"
                >
                  导出
                </el-button>
                <el-button
                  v-else-if="
                    item.type === 'gradeRank' || item.type === 'classRank'
                  "
                  size="mini"
                  type="primary"
                  plain
                  @click="detail(item)"
                >
                  查看成绩
                </el-button>

                <el-button
                  v-else
                  size="mini"
                  type="primary"
                  plain
                  @click="detail(item)"
                >
                  查看
                </el-button>
              </div>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </template>
      </div>
      <no-data v-else></no-data>
    </div>
    <el-dialog
      v-loading="exportLoading"
      title="导出原始小题数据"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <div class="download">
        <div class="title">导出各学科原始数据包（score_detail.zip）</div>
        <el-button
          type="primary"
          @click="exportGetoneexamoriginalquestiondata({ type: 1 })"
          >导出</el-button
        >
        <div class="tag">
          提示：该功能可以导出正常卡学生每个客观题的选项、每道题的得分情况
        </div>
        <div class="title">
          导出各学科原始数据详细包（score_detail_with_order.zip）
        </div>
        <el-button
          type="primary"
          @click="exportGetoneexamoriginalquestiondata({ type: 2 })"
          >导出</el-button
        >
        <div class="tag">
          提示：导出正常卡学生整体排名，校内排名、成绩、主观题成绩、每个客观题的选项、每道题的得分情况
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import filterComponent from "../components/filter.vue";
import { getStore } from "@/core/util/store";
import { validateFunc, validateTeacherNoAnyRole } from "./roleValidate";
import {
  getstatsubjectoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { examTypeOptions, subjectOptions } from "@/core/util/constdata";
// import { fileDownloadByUrl } from "@/core/util/util";
import { MessageBox } from "element-ui";
import {
  statoneexam,
  getclassstudentlist,
  getoneexamoriginalquestiondata,
} from "@/core/api/academic/onceExam";
import { run } from "./exportMap";
import { getstatscoretypesetting } from "@/core/api/academic/newExam";
export default {
  name: "OnceExamStat",
  components: {
    filterComponent,
  },
  data() {
    return {
      validateFunc: validateFunc.bind(this),
      dialogVisible: false,
      filterOptions: [
        {
          label: "入学年份：",
          field: "examYear",
          options: [],
          type: "select",
          placeholder: "全部",
        },
        {
          label: "考试时间：",
          field: "examDateYear",
          options: [],
          type: "select",
          placeholder: "全部",
        },
        {
          label: "考试类型：",
          field: "examType",
          options: [...examTypeOptions],
          type: "select",
          placeholder: "全部",
        },
        {
          label: "考试科目：",
          field: "examSubjectId",
          options: [...subjectOptions],
          type: "select",
          placeholder: "全部",
        },
      ],
      containerLoading: false,
      subjectList: [],
      noTotalSubject: [],
      classList: [],
      studentList: [],
      options: [],
      search: {},
      statId: "",
      itemData: {},
      // 1-任课教师 2-班主任 3-备课组长 4-年级组长 5-校领导
      contentArr: [
        {
          title: "年级榜",
          subjectId: 0,
          export: false,
          type: "gradeRank",
          text: "说明：选择总分或者一个科目，可以查看对应的成绩榜、等级榜",
          level: true,
          totalSubject: true,
          role: [3, 4, 5],
        },
        {
          title: "班级榜",
          subjectId: 0,
          export: false,
          classNum: "",
          type: "classRank",
          text: "说明：选择一个班级，选择总分或者一个科目，可以查看对应的成绩榜、等级榜",
          level: true,
          totalSubject: true,
          role: [1, 2, 3, 4, 5],
          classList: [],
        },
        {
          title: "各项指标对比",
          export: false,
          subjectId: 0,
          type: "targetCompare",
          text: "说明：选择一个科目，可以查看这个科目班级统计人数，最高分，最低分，平均分，中位数，优秀率，及格率等各种比率，标准差，各等级人数以及年级前N名人数对比",
          totalSubject: true,
          role: [3, 4, 5],
        },
        {
          title: "分数段对比",
          export: false,
          subjectId: 0,
          type: "scoreCompare",
          text: "说明：选择总分或者一个科目，可以查看这个科目的分数段人数统计",
          totalSubject: true,
          role: [3, 4, 5],
        },
        {
          title: "年级试卷分析",
          subjectId: "",
          export: false,
          type: "gradePaper",
          text: "说明：选择一个科目，可以查看这个年级的得分率，满分人数，难度，区分度，信度等",
          totalSubject: false,
          role: [3, 4, 5],
        },
        {
          title: "班级单科试卷分析",
          detailTitile: "试卷分析",
          subjectId: "",
          export: false,
          classNum: "",
          type: "classSinglePaper",
          text: "说明：选择一个班级，一个科目，可以查看这个班级该科目下大题得分率、满分人数，小题的得分率、满分人数",
          totalSubject: false,
          role: [1, 2, 3, 4, 5],
          classList: [],
        },
        {
          title: "学生分析",
          detailTitile: "学生试卷分析",
          classNum: "",
          studentId: "",
          export: false,
          type: "student",
          subjectId: null,
          text: "说明：选择一个班级，可以查看这个班级学生的总分，年级排名，班级排名，各科得分，各科班级排名，各科年级排名，各科卷面分析",
          role: [2, 4, 5],
        },
        {
          title: "导出学生成绩条",
          classNum: "",
          type: "exportStudent",
          export: true,
          subjectId: null,
          text: "说明：选择一个班级，可以导出这个班级所有学生的成绩条。",
          role: [2, 4, 5],
        },
        {
          title: "典型试卷",
          subjectId: "",
          export: false,
          type: "typicalPeper",
          text: "说明：选择一个科目，可以查看这个科目的典型试卷",
          totalSubject: false,
          role: [1, 2, 3, 4, 5],
        },
        {
          title: "导出答题卡",
          export: false,
          subjectId: null,
          type: "expoerPaper",
          text: "说明：进入导出学生答题卡的功能页面，选择班级、科目，批量导出带有教师批注的学生试卷。",
          role: [1, 2, 3, 4, 5],
        },
        {
          title: "批量导出",
          subjectId: null,
          type: "exportAll",
          export: true,
          text: "说明：导出本次考试所有统计分析结果",
          role: [4, 5],
        },
        {
          title: "导出原始小题数据",
          classNum: null,
          type: "exportQuestion",
          subjectId: null,
          export: true,
          text: "说明：点击导出弹框，可以导出各学科原始数据包或导出各学科原始数据详细包",
          role: [],
        },
      ],
      exportLoading: false,
      scoreType: null,
      currentExam: {},
    };
  },
  computed: {
    examTitle() {
      const item = this.options.find((item) => item.id === this.statId);
      if (item) {
        return item.name;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.setExamYearOptions();
    this.statoneexam();
  },
  methods: {
    async getstatscoretypesettingData() {
      const res = await getstatscoretypesetting({
        id: this.statId,
      });
      this.scoreType = res.data.data;
    },
    async subjectChange(item, index) {
      if (item.title === "班级榜" || item.title === "班级单科试卷分析") {
        const res = await getstatclassoption({
          id: this.statId,
          subjectId: item.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
        this.contentArr[index].classList = res.data.data;
        this.contentArr[index].classNum = res.data.data[0].name;
        this.$forceUpdate();
      }
      if (item.title == "班级榜" || item.title == "年级榜") {
        const data = this.subjectList.find((it) => it.id == item.subjectId);
        if (data.hasLevelSegment == 1) {
          this.contentArr[index].level = true;
        } else {
          this.contentArr[index].level = false;
        }
      }
    },
    validateTeacherNoAnyRole: validateTeacherNoAnyRole,
    async exportGetoneexamoriginalquestiondata(param) {
      this.exportLoading = true;
      try {
        const res = await getoneexamoriginalquestiondata({
          ...param,
          statId: this.statId,
          classNum: this.itemData.classNum,
        });
        const { url, name } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.exportLoading = false;
      } catch {
        this.exportLoading = false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    statIdChange(e) {
      let exam = this.options.find((item) => item.id === e);
      this.$router.replace({
        path: "/academic/once",
        query: {
          statId: this.statId,
          examId: exam.examId,
          ruleId: exam.ruleId,
        },
      });
      this.currentExam = exam;
      this.getstatsubjectoption();
      this.getstatclassoptionData();
      this.getstatscoretypesettingData();
    },
    detail(item, type) {
      this.itemData = { ...item };
      if (item.type == "student") {
        const student = this.studentList.find(
          (it) => it.studentId === item.studentId
        );

        let routeUrl = this.$router.resolve({
          path: "/academic/once/muchTableDetail",
          query: {
            subjectId: item.subjectId,
            title: "学生试卷分析",
            examName: this.examTitle,
            studentName: student.name ? student.name : null,
            studentId: item.studentId ? item.studentId : null,
            statId: this.statId,
            type: "student",
            classNum: item.classNum ? item.classNum : null,
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (item.type == "typicalPeper") {
        let title = item.title;
        const subject = this.subjectList.find((it) => it.id === item.subjectId);
        let routeUrl = this.$router.resolve({
          path: "/academic/once/muchTableDetail",
          query: {
            subjectId: item.subjectId,
            title: title,
            examName: this.examTitle,
            subjectName: subject.name ? subject.name : null,
            statId: this.statId,
            examId: this.$route.query.examId,
            type: "typicalPeper",
          },
        });
        window.open(routeUrl.href, "_blank");
      } else if (item.type === "expoerPaper") {
        this.$router.push({
          path: "/academic/once/setting",
          query: {
            title: item.title,
            examName: this.examTitle,
            examId: this.$route.query.examId,
            statId: this.statId,
            type: "expoerPaper",
          },
        });
      } else if (item.type === "exportQuestion") {
        this.dialogVisible = true;
      } else if (item.type === "exportStudent") {
        this.$set(item, "loading", true);
        run({
          detail: 0,
          classNum: item.classNum,
          ...item,
          statId: this.statId,
        })
          .then(() => {
            this.$set(item, "loading", false);
          })
          .catch(() => {
            this.$set(item, "loading", false);
          });
      } else if (item.type === "exportAll") {
        this.$set(item, "loading", true);
        run({
          detail: 0,
          classNum: item.classNum,
          ...item,
          statId: this.statId,
        })
          .then(() => {
            this.$set(item, "loading", false);
            setTimeout(() => {
              MessageBox.close();
            }, 2000);
          })
          .catch(() => {
            this.$set(item, "loading", false);
            this.loading = false;
            setTimeout(() => {
              MessageBox.close();
            }, 2000);
          });
      } else {
        if (this.$route.query.ruleId > 0 && this.scoreType == 1) {
          let path;
          if (item.type == "classRank") {
            path = "/academic/once/NewExam/ClassRank";
          } else if (item.type == "gradeRank") {
            path = "/academic/once/NewExam/GradeRank";
          } else if (item.type == "targetCompare") {
            path = "/academic/once/NewExam/TargetCompare";
          } else if (item.type == "scoreCompare") {
            path = "/academic/once/NewExam/ScoreCompare";
          } else {
            path = "/academic/once/detail";
          }
          let subject = this.subjectList.find((it) => it.id === item.subjectId);
          let routeUrl = this.$router.resolve({
            path: path,
            query: {
              subjectId: item.subjectId,
              examName: this.examTitle,
              statId: this.statId,
              classNum: item.classNum ? item.classNum : null,
              examId: this.$route.query.examId,
              type: type === "level" ? `${item.type}Level` : item.type,
              ruleId: this.$route.query.ruleId,
              subjectName: subject.name,
            },
          });
          window.open(routeUrl.href, "_blank");
        } else {
          const subject = this.subjectList.find(
            (it) => it.id === item.subjectId
          );
          let title = item.title;
          if (item.title === "班级榜" || item.title === "年级榜") {
            if (type === "level") {
              title = "等级榜";
            } else {
              title = "成绩榜";
            }
          } else if (title === "班级单科试卷分析") {
            title = "试卷分析";
          }
          let routeUrl = this.$router.resolve({
            path: "/academic/once/detail",
            query: {
              subjectId: item.subjectId,
              title: title,
              examName: this.examTitle,
              subjectName: subject.name,
              examId: this.$route.query.examId,
              statId: this.statId,
              classNum: item.classNum ? item.classNum : null,
              type: type === "level" ? `${item.type}Level` : item.type,
              ruleId: this.$route.query.ruleId,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      }
    },
    classChange(it, e) {
      if (it.type == "student") {
        this.getStudentList(e);
      }
    },
    async getStudentList(e) {
      const res = await getclassstudentlist({ id: this.statId, classNum: e });
      this.studentList = res.data.data;
      this.contentArr.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(item, "studentId")) {
          item.studentId = res.data.data && res.data.data[0].studentId;
        }
      });
    },
    async statoneexam() {
      this.containerLoading = true;
      const res = await statoneexam({ ...this.search });
      this.options = res.data.data;
      this.containerLoading = false;
      if (res.data.data.length) {
        if (this.$route.query.statId) {
          this.statId = Number(this.$route.query.statId);
          this.currentExam = res.data.data.find((it) => it.id == this.statId);
          this.getstatscoretypesettingData();
        } else {
          this.$router.replace({
            path: "/academic/once",
            query: { statId: res.data.data[0]?.id },
          });
        }
        this.getstatsubjectoption();
        this.getstatclassoptionData();
      } else {
        this.statId = null;
      }
    },
    initSubjectList() {
      let maxRoleType, subjects;
      const { roleId, teacherRoleType, teacherRoleList } = getStore({
        name: "userInfo",
      });
      if (roleId == 5) {
        maxRoleType = Math.max(...teacherRoleType.split(",").map(Number));
        let maxRoleList = teacherRoleList.filter(
          (item) => item.type == maxRoleType
        );
        let roleItems = maxRoleList.filter(
          (item) => item.year == this.currentExam.year
        );
        let roleSubjects = roleItems.map((item) => item.subjectId);
        let subjectIds = this.currentExam.subjectIds.split(",").map(Number);
        subjects = subjectIds.filter((item) => roleSubjects.includes(item));
      }
      const data = this.noTotalSubject.filter((it) => {
        if (it.type !== 2 && it.type !== 3) {
          return it;
        }
      });
      this.contentArr.forEach((item) => {
        if (["年级试卷分析", "班级单科试卷分析"].includes(item.title)) {
          if (
            roleId == 5 &&
            maxRoleType == 3 &&
            item.title === "年级试卷分析"
          ) {
            let result = data.filter((item) => subjects.includes(item.id));
            this.$set(item, "subjectList", result);
            this.$set(item, "subjectId", result[0].id);
          } else {
            this.$set(item, "subjectList", data);
          }
        } else {
          if (item.totalSubject) {
            if (roleId == 5 && maxRoleType == 3 && item.title === "年级榜") {
              let result = this.subjectList.filter((item) =>
                subjects.includes(item.id)
              );
              this.$set(item, "subjectList", result);
              this.$set(item, "subjectId", result[0].id);
            } else {
              this.$set(item, "subjectList", this.subjectList);
            }
          } else {
            this.$set(item, "subjectList", this.noTotalSubject);
          }
        }
      });
    },
    async getstatsubjectoption() {
      // 初始化所有卡片的科目 在新高考需要检测是否有ruleId 如果存在ruleId，
      // 将把科目重置为必考单科、选考组合、选考单科等。跳转页面也需要改变
      this.containerLoading = true;
      const res = await getstatsubjectoption({
        id: this.statId,

        hasTotal: 1,
      });
      this.subjectList = res.data.data;
      const hasTotal = this.subjectList.some((item) => item.id === 0);
      this.noTotalSubject = res.data.data.filter((item) => item.id != 0);

      this.contentArr.forEach((item, index) => {
        for (let key in item) {
          if (key == "subjectId") {
            if (hasTotal) {
              if (item[key] !== 0 && item[key] !== null) {
                item[key] = this.noTotalSubject[0].id;
              }
            } else {
              item[key] = this.noTotalSubject[0].id;
            }
          }
        }
        this.containerLoading = false;
        this.subjectChange(item, index);
      });
      this.initSubjectList();
    },
    async getstatclassoptionData() {
      this.containerLoading = true;
      const res = await getstatclassoption({ id: this.statId });
      this.classList = res.data.data;
      this.contentArr.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(item, "classNum")) {
          if (item.classNum == "") {
            item.classNum = res.data.data && res.data.data[0].name;
          }
        }
      });
      const classNum = res.data.data.length && res.data.data[0].name;
      this.containerLoading = false;
      this.getStudentList(classNum);
    },
    setExamYearOptions() {
      const year = new Date().getFullYear();
      const arr = new Array(20).fill(1);
      this.filterOptions[0].options = arr.map((item, index) => {
        return {
          label: `${year - index}级`,
          value: year - index,
        };
      });
      this.filterOptions[1].options = arr.map((item, index) => {
        return {
          label: `${year - index}`,
          value: year - index,
        };
      });
    },
    getFilterValue(val) {
      this.search = { ...val };
      this.statoneexam();
    },
  },
};
</script>
<style lang="scss" scoped>
.once {
  .filter-box {
    display: flex;
    align-items: center;
  }

  .exam-title {
    color: #080a09;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    .content-item {
      width: calc((100%) / 3 - 24px);
      height: 214px;
      border: 1px solid #dad9d9;
      margin-right: 24px;
      margin-bottom: 24px;
      box-sizing: border-box;
      padding: 24px;

      &-title {
        margin-bottom: 24px;

        img {
          width: 14px;
          height: 16px;
          margin-right: 8px;
        }

        span {
          color: #080a09;
          font-size: 14px;
          margin-bottom: 24px;
        }
      }

      .operate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }

      .text {
        font-size: 12px;
        color: #a1a4a9;
        line-height: 1.2;
      }
    }

    & :nth-child(3n) {
      margin-right: 0;
    }
  }
}

::v-deep .el-select .el-input__inner {
  padding-right: 0 !important;
  padding-left: 5px !important;
}

.download {
  .title {
    font-weight: 500;
    color: #3f87f4;
    font-size: 14px;
    margin-bottom: 24px;
  }

  .tag {
    margin: 24px 0;
    height: 40px;
    line-height: 40px;
    background-color: #f2f8fe;
    color: #737677;
    box-sizing: border-box;
    padding: 0 16px;
    border: 2px dashed #2474ed;
    font-size: 12px;
  }
}
::v-deep .el-dialog__body {
  padding: 15px 24px 10px;
}
</style>
