// import { fileDownloadByUrl } from "@/core/util/util";
import { MessageBox, Message } from "element-ui";
import router from "@/router";
import {
  getoneexamgradelist,
  getoneexamclasslist,
  exportIndicatorCompare,
  exportGradePaperAnaly,
  exportClassPaperAnaly,
  exportStudentScoreReport,
  exportTypicalAnswerSheet,
  exportAll,
  exportAnswerSheet,
  exportScoreSectionCompare,
} from "@/core/api/academic/onceExam";
const exportMap = new Map([
  // 年级榜
  [
    {
      type: "gradeRank",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId } = params;
      return getoneexamgradelist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        type: subjectId == "0" ? 1 : 3,
      });
    },
  ],
  [
    {
      type: "gradeRank",
      detail: 1, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId } = params;
      return getoneexamgradelist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        type: subjectId == "0" ? 2 : 4,
      });
    },
  ],
  [
    {
      type: "gradeRankLevel",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId } = params;
      return getoneexamgradelist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        type: subjectId == "0" ? 5 : 6,
      });
    },
  ],
  // 班级榜
  [
    {
      type: "classRank",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId, classNum } = params;
      return getoneexamclasslist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        classNums: classNum,
        type: subjectId == "0" ? 1 : 3,
      });
    },
  ],
  [
    {
      type: "classRank",
      detail: 1, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId, classNum } = params;
      return getoneexamclasslist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        classNums: classNum,
        type: subjectId == "0" ? 2 : 4,
      });
    },
  ],
  [
    {
      type: "classRankLevel",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { subjectId, statId, classNum } = params;
      return getoneexamclasslist({
        subjectIds: subjectId == "0" ? "" : subjectId,
        statId: statId,
        classNums: classNum,
        type: subjectId == "0" ? 5 : 6,
      });
    },
  ],
  [
    {
      type: "targetCompare",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, subjectId } = params;
      return exportIndicatorCompare({
        statId: statId,
        subjectIds: subjectId == "0" ? "" : subjectId,
        type: subjectId == "0" ? 1 : 3,
      });
    },
  ],
  [
    {
      type: "targetCompare",
      detail: 1, // 0不为详情 1为详情
    },
    (params) => {
      const { statId } = params;
      return exportIndicatorCompare({
        statId: statId,
        subjectIds: "",
        type: 2,
      });
    },
  ],
  [
    {
      type: "scoreCompare",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, subjectId } = params;
      return exportScoreSectionCompare({
        statId: statId,
        subjectIds: subjectId,
      });
    },
  ],
  [
    {
      type: "exportQuestion",
      detail: 2, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, classNum, valuetype } = params;
      return exportIndicatorCompare({
        statId: statId,
        classNum: classNum,
        type: valuetype,
      });
    },
  ],
  [
    {
      type: "gradePaper",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, subjectId } = params;
      return exportGradePaperAnaly({
        statId: statId,
        subjectIds: subjectId,
      });
    },
  ],
  [
    {
      type: "classSinglePaper",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, subjectId, classNum } = params;
      return exportClassPaperAnaly({
        statId: statId,
        classNums: classNum,
        subjectIds: subjectId,
      });
    },
  ],
  [
    {
      type: "exportStudent",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, classNum } = params;
      return exportStudentScoreReport({
        statId: statId,
        classNums: classNum,
      });
    },
  ],
  [
    {
      type: "typicalPeper",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId, subjectId } = params;
      return exportTypicalAnswerSheet({
        statId: statId,
        subjectIds: subjectId,
      });
    },
  ],
  [
    {
      type: "expoerPaper",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const {
        statId,
        classNum,
        subjectId,
        studentExamIdInFront,
        subjectInFront,
        addTeacherName,
      } = params;
      return exportAnswerSheet({
        statId: statId,
        classNums: classNum,
        subjectIds: subjectId,
        studentExamIdInFront: studentExamIdInFront,
        subjectInFront: subjectInFront,
        addTeacherName: addTeacherName,
      });
    },
  ],
  [
    {
      type: "exportAll",
      detail: 0, // 0不为详情 1为详情
    },
    (params) => {
      const { statId } = params;
      if (statId) {
        MessageBox.alert("系统正在对数据进行打包，请稍后！", "", {
          confirmButtonText: "确定",
        });
        return exportAll({
          statId: statId,
        });
      } else {
        Message.error("考试不能为空！请选择考试");
      }
    },
  ],
]);
const run = (params) => {
  return new Promise((resolve, reject) => {
    const action = [...exportMap].filter(
      ([key]) => key.type === params.type && key.detail === params.detail
    );
    action.forEach(async ([key, value]) => {
      if (key) {
        try {
          const res = await value(params);
          const { name, url } = res.data.data;
          const { href } = router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          resolve();
        } catch {
          reject();
        }
      }
    });
  });
};
export { run };
