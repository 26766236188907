import request from "@/core/services/axios";
export function statoneexam(params) {
  // 统计列表
  return request({
    url: `/report/statoneexam/list`,
    method: `post`,
    data: params,
  });
}
export function getclassstudentlist(params) {
  // 根据班级获取学生列表
  return request({
    url: `/report/statoneexam/getclassstudentlist`,
    method: `get`,
    params: params,
  });
}
export function getoneexamgradelisttotalscore(params) {
  // 年级榜-总分-成绩
  return request({
    url: `/report/statoneexam/getoneexamgradelisttotalscore`,
    method: `post`,
    data: params,
  });
}
export function getoneexamgradelisttotallevel(params) {
  // 年级榜-总分-等级
  return request({
    url: `/report/statoneexam/getoneexamgradelisttotallevel`,
    method: `post`,
    data: params,
  });
}
export function getoneexamgradelistsinglescore(params) {
  // 年级榜-单科-成绩
  return request({
    url: `/report/statoneexam/getoneexamgradelistsinglescore`,
    method: `post`,
    data: params,
  });
}
export function getoneexamgradelistsinglelevel(params) {
  // 年级榜-单科-等级
  return request({
    url: `/report/statoneexam/getoneexamgradelistsinglelevel`,
    method: `post`,
    data: params,
  });
}
export function getoneexamclasslisttotalscore(params) {
  // 班级榜-总分-成绩
  return request({
    url: `/report/statoneexam/getoneexamclasslisttotalscore`,
    method: `get`,
    params: params,
  });
}
export function getoneexamclasslisttotallevel(params) {
  // 班级榜-总分-等级
  return request({
    url: `/report/statoneexam/getoneexamclasslisttotallevel`,
    method: `get`,
    params: params,
  });
}
export function getoneexamclasslistsinglescore(params) {
  // 班级榜-单科-成绩
  return request({
    url: `/report/statoneexam/getoneexamclasslistsinglescore`,
    method: `get`,
    params: params,
  });
}
export function getoneexamclasslistsinglelevel(params) {
  // 班级榜-单科-等级
  return request({
    url: `/report/statoneexam/getoneexamclasslistsinglelevel`,
    method: `get`,
    params: params,
  });
}
export function getoneexamindicatorcomparetotal(params) {
  // 各项指标对比-总分
  return request({
    url: `/report/statoneexam/getoneexamindicatorcomparetotal`,
    method: `get`,
    params: params,
  });
}
export function getoneexamindicatorcomparesingle(params) {
  // 各项指标对比-单科
  return request({
    url: `/report/statoneexam/getoneexamindicatorcomparesingle`,
    method: `get`,
    params: params,
  });
}
export function getoneexamindicatorcomparetotaltopsection(params) {
  //各项指标对比-总分-前N名
  return request({
    url: `/report/statoneexam/getoneexamindicatorcomparetotaltopsection`,
    method: `get`,
    params: params,
  });
}
export function getoneexamindicatorcomparesingletopsection(params) {
  //各项指标对比-单科-前N名
  return request({
    url: `/report/statoneexam/getoneexamindicatorcomparesingletopsection`,
    method: `get`,
    params: params,
  });
}
export function getoneexamscoresegmentcompare(params) {
  //分数段对比
  return request({
    url: `/report/statoneexam/getoneexamscoresegmentcompare`,
    method: `get`,
    params: params,
  });
}
export function getoneexamgradepaperanaly(params) {
  //年级试卷分析
  return request({
    url: `/report/statoneexam/getoneexamgradepaperanaly`,
    method: `get`,
    params: params,
  });
}
export function getoneexamclasspaperanaly(params) {
  //班级单科试卷分析
  return request({
    url: `/report/statoneexam/getoneexamclasspaperanaly`,
    method: `get`,
    params: params,
  });
}
export function getoneexamstudentanaly(params) {
  //学生分析
  return request({
    url: `/report/statoneexam/getoneexamstudentanaly`,
    method: `get`,
    params: params,
  });
}
export function getoneexamtypicalpaper(params) {
  //典型试卷
  return request({
    url: `/report/statoneexam/getoneexamtypicalpaper`,
    method: `get`,
    params: params,
  });
}
export function getoneexamgradelist(params) {
  //导出年级榜
  return request({
    url: `/report/statoneexamexport/getoneexamgradelist`,
    method: `post`,
    data: params,
  });
}
export function getoneexamclasslist(params) {
  //导出班级榜
  return request({
    url: `/report/statoneexamexport/getoneexamclasslist`,
    method: `post`,
    data: params,
  });
}
export function exportIndicatorCompare(params) {
  //导出各项指标对比
  return request({
    url: `/report/statoneexamexport/exportIndicatorCompare`,
    method: `post`,
    data: params,
  });
}
export function exportScoreSectionCompare(params) {
  //导出分数段对比
  return request({
    url: `/report/statoneexamexport/exportScoreSectionCompare`,
    method: `post`,
    data: params,
  });
}
export function getoneexamoriginalquestiondata(params) {
  //导出原始小题数据
  return request({
    url: `/report/statoneexamexport/getoneexamoriginalquestiondata`,
    method: `post`,
    data: params,
  });
}
export function exportGradePaperAnaly(params) {
  //导出年级试卷分析
  return request({
    url: `/report/statoneexamexport/exportGradePaperAnaly`,
    method: `post`,
    data: params,
  });
}
export function exportClassPaperAnaly(params) {
  //导出班级试卷分析
  return request({
    url: `/report/statoneexamexport/exportClassPaperAnaly`,
    method: `post`,
    data: params,
  });
}
export function exportStudentScoreReport(params) {
  //导出班级试卷分析
  return request({
    url: `/report/statoneexamexport/exportStudentScoreReport`,
    method: `post`,
    data: params,
  });
}
export function exportTypicalAnswerSheet(params) {
  //导出典型学生答题卡
  return request({
    url: `/report/statoneexamexport/exportTypicalAnswerSheet`,
    method: `post`,
    data: params,
  });
}
export function exportStudentPaperAnaly(params) {
  //导出学生试卷分析
  return request({
    url: `/report/statoneexamexport/exportStudentPaperAnaly`,
    method: `post`,
    data: params,
  });
}
export function exportAll(params) {
  //导出所有报告
  return request({
    url: `/report/statoneexamexport/exportAll`,
    method: `post`,
    data: params,
  });
}
export function exportAnswerSheet(params) {
  //导出学生答题卡数据
  return request({
    url: `/report/statoneexamexport/exportAnswerSheet`,
    method: `post`,
    data: params,
  });
}

// --------------------新高考接口-----------------------
export function newExamGradelist(params) {
  //新高考年级榜
  return request({
    url: `/report/statnewgaokaooneexam/gradelist`,
    method: `post`,
    data: params,
  });
}
export function newExamClasslist(params) {
  //新高考班级榜

  return request({
    url: `/report/statnewgaokaooneexam/classlist`,
    method: `post`,
    data: params,
  });
}
export function indicatorcompare(params) {
  //新高考各项指标对比

  return request({
    url: `/report/statnewgaokaooneexam/indicatorcompare`,
    method: `post`,
    data: params,
  });
}
export function scoresegmentcompare(params) {
  //新高考分数段对比接口

  return request({
    url: `/report/statnewgaokaooneexam/scoresegmentcompare`,
    method: `post`,
    data: params,
  });
}
export function exportgradelist(params) {
  //新高考导出年级榜
  return request({
    url: `/report/statnewgaokaooneexam/exportgradelist`,
    method: `post`,
    data: params,
  });
}
export function exportclasslist(params) {
  //新高考导出班级榜
  return request({
    url: `/report/statnewgaokaooneexam/exportclasslist`,
    method: `post`,
    data: params,
  });
}
export function exportindicatorcompare(params) {
  //新高考各项指标对比导出
  return request({
    url: `/report/statnewgaokaooneexam/exportindicatorcompare`,
    method: `post`,
    data: params,
  });
}
export function exportscoresegmentcompare(params) {
  //新高考分数段对比导出
  return request({
    url: `/report/statnewgaokaooneexam/exportscoresegmentcompare`,
    method: `post`,
    data: params,
  });
}
